import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, IScenario, IScenarioDetails, IScenarioPayload, ISharedUserList, Methods, NUMBER } from '../../constants';
import { startFullLoading, stopFullLoading } from '../SpinnerSlice';
import { interceptor } from '../../utils/interceptor';

interface IScenarioId {
  scenarioId: string
}

export interface IUpdatePayload {
  scenario_id: string
  name: string
  description: string
}

export interface IApproveReject {
  scenario_id: string
  decision: string
  reason: string
}

export const scenarioApis = createApi({
  reducerPath: 'scenarioApis',
  baseQuery: interceptor,
  tagTypes: ['GetScenario', 'GetRecentScenario', 'GetSharedUser', 'compareScenario', 'compareEVScenario', 'GetScenarioDetails'],
  endpoints: (builder) => ({
    // api to save Project Data...
    saveScenario: builder.mutation<any, IScenarioPayload>({
      query: (payload) => ({
        url: ApiEndpoints.CREATE_SCENARIO,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['GetScenario', 'GetRecentScenario', 'GetScenarioDetails']
    }),

    /**
     * @description Api to update the scenario name and description
     */
    updateScenario: builder.mutation<IScenarioDetails, IUpdatePayload>({
      query: (payload: IUpdatePayload) => ({
        url: `${ApiEndpoints.EDIT_SCENARIO}?scenario_id=${payload.scenario_id}`,
        method: Methods.POST,
        body: { name: payload.name, description: payload.description }
      }),
      invalidatesTags: ['GetScenario', 'GetRecentScenario', 'GetScenarioDetails']
    }),

    // api ALL scenario List...
    getScenario: builder.query<any, string>({
      query: (projectID) => ({
        url: `${ApiEndpoints.GET_ALL_SCENARIO}?projectID=${projectID}`,
        method: Methods.GET
      }),
      invalidatesTags: undefined,
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      providesTags: ['GetScenario']
    }),

    getScenarioDetails: builder.query<IScenarioDetails, string>({
      query: (scenarioId) => ({
        url: `${ApiEndpoints.GET_SCENARIO_DETAILS}?scenario_id=${scenarioId}`,
        method: Methods.GET
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      keepUnusedDataFor: NUMBER.N0,
      providesTags: ['GetScenarioDetails']
    }),

    // api to get remove scemario...
    removeScenario: builder.mutation<IScenario, IScenarioId>({
      query: (payload) => ({
        url: ApiEndpoints.REMOVE_SCENARIO,
        method: Methods.DELETE,
        body: payload
      }),
      invalidatesTags: ['GetScenario', 'GetRecentScenario']
    }),
    // api to get the list of recent three scenarios
    getRecentScenarios: builder.query<any, string>({
      query: (email) => ({
        url: `${ApiEndpoints.GET_RECENT_SCENARIOS}?user_email=${email}`,
        method: Methods.GET
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      providesTags: ['GetRecentScenario']
    }),

    // api to get the list of recent three scenarios
    getSharedUser: builder.query<ISharedUserList[], string>({
      query: (scenarioId) => ({
        url: `${ApiEndpoints.GET_SHARED_USER}?subprojectid=${scenarioId}`,
        method: Methods.GET
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      providesTags: ['GetSharedUser']
    }),

    // api to compare scemario...
    compareScenario: builder.mutation<any, any>({
      query: (payload) => ({
        url: ApiEndpoints.COMPARE_SCENARIO,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['compareScenario']
    }),

    // api for comparing scenario based on graphs...
    compareScenarioGraphPlotting: builder.mutation<any, any>({
      query: (payload) => ({
        url: ApiEndpoints.COMPARE_GRAPH_VIEW,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['compareScenario']
    }),

    // test api for comparing scenario based on graphs...
    compareScenarioGeoGraphPlotting: builder.mutation<any, any>({
      query: (payload) => ({
        url: ApiEndpoints.TEST_GEO_COMPARE_API,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['compareScenario']
    }),

    compareEVScenario: builder.mutation<any, any>({
      query: (payload) => ({
        url: ApiEndpoints.COMPARE_EV_SCENARIO,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['compareEVScenario']
    }),

    /**
     * @description Api to duplicate any scenario
     */
    duplicateScenario: builder.mutation<any, any>({
      query: (payload) => ({
        url: ApiEndpoints.DUPLICATE_SCENARIO,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['GetRecentScenario', 'GetScenario']
    }),

    /**
     * @description mutation Api call to get scenario details
     */
    getDetails: builder.mutation<IScenarioDetails, string>({
      query: (scenarioId) => ({
        url: `${ApiEndpoints.GET_SCENARIO_DETAILS}?scenario_id=${scenarioId}`,
        method: Methods.GET
      })
    }),

    /**
     * @description Api call to get scenario Shared user list
     */
    getSharedUserList: builder.query<any, string>({
      query: (scenarioId) => ({
        url: `${ApiEndpoints.GET_SHARED_USER_LIST}?subprojectid=${scenarioId}`,
        method: Methods.GET
      })
    }),

    /**
     * @description Api to share scenario with some user
     */
    shareScenario: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.SHARE_SCENARIO}${payload.scenarioId}`,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['GetSharedUser']
    }),

    // api to get ALL shared scenario List...
    getSharedScenario: builder.query<any, void>({
      query: () => ({
        url: ApiEndpoints.GET_SHARED_SCENARIOS,
        method: Methods.GET
      }),
      transformResponse: (res: IScenarioDetails[]) => {
        return res.reverse();
      }
    }),

    // api to get similar projects to move scenario

    getSimilarProjects: builder.query<any, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.GET_SIMILAR_PROJECTS}?scenario_id=${payload}`,
        method: Methods.GET
      })
    }),

    // api to move scenario

    moveScenario: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${ApiEndpoints.MOVE_SCENARIO}`,
        method: Methods.POST,
        body: payload
      })
    }),

    // api to remove shared scenario to a user...
    unshareScenario: builder.mutation<IScenario, IScenarioId>({
      query: (payload) => ({
        url: `${ApiEndpoints.UNSHARE_SCENARIO}${payload.scenarioId}`,
        method: Methods.POST,
        body: payload
      })
    }),
    /**
* @description Api to update the scenario name and description
*/
    editScenario: builder.mutation<IScenarioDetails, IUpdatePayload>({
      query: (payload: IUpdatePayload) => ({
        url: `${ApiEndpoints.EDIT_SCENARIO}?scenario_id=${payload.scenario_id}`,
        method: Methods.POST,
        body: { name: payload.name, description: payload.description }
      })
    }),

    approveRejectScenario: builder.mutation<any, IApproveReject>({
      query: (payload: IApproveReject) => ({
        url: `${ApiEndpoints.APPROVE_REJECT}?scenario_id=${payload.scenario_id}`,
        method: Methods.POST,
        body: { decision: payload.decision, reason: payload.reason }
      }),
      invalidatesTags: ['GetScenarioDetails']
    })
  })
});

export const {
  useSaveScenarioMutation,
  useUpdateScenarioMutation,
  useGetScenarioQuery,
  useLazyGetScenarioQuery,
  useRemoveScenarioMutation,
  useGetRecentScenariosQuery,
  useCompareScenarioMutation,
  useCompareScenarioGraphPlottingMutation,
  useCompareScenarioGeoGraphPlottingMutation,
  useCompareEVScenarioMutation,
  useGetScenarioDetailsQuery,
  useDuplicateScenarioMutation,
  useGetDetailsMutation,
  useGetSharedUserListQuery,
  useShareScenarioMutation,
  useGetSharedUserQuery,
  useGetSharedScenarioQuery,
  useGetSimilarProjectsQuery,
  useMoveScenarioMutation,
  useUnshareScenarioMutation,
  useEditScenarioMutation,
  useApproveRejectScenarioMutation
} = scenarioApis;
