import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { NavDropdown, Dropdown, Button, Container, Nav, Navbar } from 'react-bootstrap';
import { backgroundDarkOn, backgroundLogo, backgroundLogoDark, exportExcel, exportJson, headerCloseBtn, headerDarkLogo, headerLogo, settingIocn } from '../../utils/icons';
import ConfirmationAlert from '../shared/ConfirmationAlert';
import { EV, IScenarioSettings, MESSAGES, NUMBER, THEME_CLASS_NAME, USER_PERMISSIONS } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { DASHBOARD, SCENARIOS, getApprovalStaus } from '../../utils';
import WorkBenchSettings from '../workbench/WorkBenchSettings';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateTheme } from '../../redux/workbenchSlice';
import { useExportJson } from '../../hooks/useExportJson';
import { useSaveWorkbench } from '../../hooks/useSaveWorkbenchData';
import { LABELS } from '../../constants/labelConstant';
import ScenarioSharedList from '../shared/ScenarioSharedList';
import { useSaveWorkbenchEV } from '../../hooks/useSaveWorkbenchDataEV';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import RequestApproval from '../approval-workflow/RequestApproval';
import { useGetApprovalStatusQuery, useStartApprovalProcessMutation } from '../../redux/services/requestApprovalApis';
import { resetApprovalState, updateApprovalData } from '../../redux/slices/approvalSlice';
import { hasPermissionToImportExcel } from '../../utils/helper';
import { useSaveSettingsMutation } from '../../redux/services/settingsApis';
import { updateWorkbenchSettings } from '../../redux/slices/settingsSlice';

interface IHeaderWorkbenchProps {
  divScrolled?: boolean | null
  navigateFrom?: string | null
  isEVHeader?: boolean | false
}

// eslint-disable-next-line complexity
function HeaderWorkbench(props: IHeaderWorkbenchProps) {
  const { divScrolled, isEVHeader } = props;
  const workbenchData = useAppSelector(state => state.workbench);
  const workbenchDataEV = useAppSelector((state) => state.workBenchEV);
  const scenarioDetails = useAppSelector((state) => state.scenarioDetails);
  const userData = useAppSelector(state => state.auth);
  const { BaselineStatus, permission: isSharedScenario } = scenarioDetails;
  const { approverList, approvalStatus } = useAppSelector(state => state.approvalData);
  const { S3_URI_JSON, 'POWER GENERATION PROFILE': powerGenerationProfile } = useAppSelector(state => state.simulationMetricsData);
  const isScenarioSimulated = powerGenerationProfile || S3_URI_JSON;
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioType = searchParams.get('type');
  const scenarioID = searchParams.get('scenario_id');
  const isSharedOrLockScenario = scenarioDetails.permission ?? BaselineStatus; // Revoke permission when scenario is shared or baseline ...
  // Revoke permission when scenario is shared as collaborator or baseline ....
  const canImportJSON = userData.permissions?.includes(USER_PERMISSIONS.import_JSON);
  const canImportExcel = hasPermissionToImportExcel(isSharedScenario ?? '', userData, !!BaselineStatus);
  const hasSharePermission = userData.permissions?.includes(USER_PERMISSIONS.share_scenario);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [showRequestApproval, setShowRequestApproval] = useState(false);
  const [showStartApprovalAlert, setShowStartApprovalAlert] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const excelFileInputRef = useRef<HTMLInputElement | null>(null);
  const { exportJsonData, handleFileChange, exportExcelData, importExcelData } = useExportJson();
  const [confirmationAlert, setConfirmationAlert] = useState(false);
  const { saveWorkbench } = useSaveWorkbench();
  const { saveWorkbenchEV } = useSaveWorkbenchEV();
  const { data: approvalData, error } = useGetApprovalStatusQuery(scenarioID as string, { skip: !scenarioID });
  const [startApproval] = useStartApprovalProcessMutation();
  const [saveSettings] = useSaveSettingsMutation();

  /**
   * UseEffect to update the store for approval data
   */
  useEffect(() => {
    if (approvalData) {
      dispatch(updateApprovalData(approvalData));
      setShowRequestApproval(true);
    }
    if (error) {
      dispatch(updateApprovalData(error));
      setShowRequestApproval(true);
    }

    return () => {
      dispatch(resetApprovalState());
    };
  }, [approvalData, error]);

  const handleWorkBenchCloseClick = () => {
    setConfirmationAlert(false);
    goBack();
  };

  const handleSaveChangesClick = () => {
    setShowLoading(true);
    if (isEVHeader) {
      saveWorkbenchEV(false, () => {
        setShowLoading(false);
        goBack();
      });
    } else {
      saveWorkbench(false, () => {
        setShowLoading(false);
        goBack();
      });
    }
  };

  const goTo = (path: string) => {
    if (workbenchData.unsavedChanges || workbenchDataEV.unsavedChanges) {
      setConfirmationAlert(true);
    } else {
      navigate(path);
    }
  };

  const updateWorkBenchTheme = () => {
    const theme = workbenchData.theme ? '' : THEME_CLASS_NAME;
    dispatch(updateTheme(theme));
  };

  // Function to manually open file explorer
  const importJsonData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      (fileInputRef.current as HTMLElement).click();
    };
  };

  const importExcel = () => {
    if (excelFileInputRef.current) {
      excelFileInputRef.current.value = '';
      (excelFileInputRef.current as HTMLElement).click();
    };
  };

  const showUnsavedAlert = () => {
    if (workbenchData.unsavedChanges || workbenchDataEV.unsavedChanges) {
      setConfirmationAlert(true);
    } else {
      goBack();
    }
  };

  const goBack = () => {
    dispatch(stopFullLoading());
    const navigateFrom = location?.state?.navigateFrom;
    if (navigateFrom === LABELS.CREATE_SCENARIO || navigateFrom === LABELS.ALL_SCENARIO_LIST) {
      const project = location?.state?.project ?? {};
      navigate(`${SCENARIOS}?projectId=${project._id}&name=${project.name}&projectType=${project.project_type}`);
    } else if (navigateFrom === LABELS.DASHBOARD) {
      navigate(DASHBOARD);
    } else if (navigateFrom === LABELS.CREATE_WITH_AI) {
      navigate(`${SCENARIOS}?projectId=${scenarioDetails?.projectID}&name=${scenarioDetails?.projectName}
      &projectType=${scenarioDetails?.subProject_Type}`);
    } else {
      navigate(-NUMBER.N1);
    }
  };

  /**
   * Function to show confirmation alert when user start approval flow...
   * @param status : approval status of scenario
   */
  const showApprovalAlert = (status: string) => {
    if (status === '') {
      setShowStartApprovalAlert(true);
    } else {
      setApprovalModal(true);
    }
  };

  /**
   * Function to call api for start the Approval flow
   */
  const startApprovalProcess = () => {
    dispatch(startFullLoading());
    startApproval(scenarioDetails.id).then((res) => {
      if ('data' in res) {
        dispatch(stopFullLoading());
        setShowStartApprovalAlert(false);
        setApprovalModal(true);
        dispatch(updateApprovalData({
          approverList: [],
          approvalStatus: 'pending'
        }));
      }
    });
  };

  const handleImportJSON = (event: ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event, (res: IScenarioSettings) => {
      const finalRes = res;
      finalRes.subProjectID = scenarioID;
      saveSettings(res)
        .then((res: any) => {
          dispatch(updateWorkbenchSettings(res));
        });
    });
  };

  return (
    <>
      <header>
        <Navbar
          collapseOnSelect
          expand="lg"
          className={`bg-body-tertiary workbench-header ${divScrolled ?? workbenchData.theme ? 'header-scroll-bg dashboard-header-scroll' : ''}`}
        >
          <Container>
            <Navbar.Brand onClick={() => goTo(DASHBOARD)}>
              {divScrolled ?? workbenchData.theme ? (
                <img
                  src={headerDarkLogo}
                  alt="header logo"
                  className="header-dark-logo"
                />
              ) : (
                <img
                  src={headerLogo}
                  alt="header logo"
                  className="header-logo"
                />
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              {scenarioType !== EV && <Nav className="me-auto">
                <Nav.Link className="background-link" onClick={updateWorkBenchTheme} >
                  <span className="background-logo">
                    {!workbenchData.theme && (
                      <img src={divScrolled ? backgroundDarkOn : backgroundLogo} alt="bg logo" />
                    )}
                    {workbenchData.theme && (
                      <img src={backgroundLogoDark} alt="bg logo" />
                    )}
                  </span>
                  Background {workbenchData.theme && ': Off'}
                </Nav.Link>
              </Nav>}
              <Nav className="nav-dropdown-wrapper">
                <div className="user-circle plus-img d-none">
                  <span className="user-icon">A</span>
                  <span className="user-icon five-plus">+1</span>
                </div>
                {scenarioType !== EV && (
                  <>
                    <div className="tooltip-container">
                      <Button
                        className="setting-btn btn-no-outline"
                        onClick={() => setShow(true)}
                      >
                        <div className="circle-logo-wrapper">
                          <span className="svg-icon">
                            <img src={settingIocn} alt="setting" />
                          </span>
                        </div>
                      </Button>
                      <div className="tooltip-text">Settings</div>
                    </div>

                    <Dropdown className="create-modal-dropdown export-dropdown">
                      <div className="tooltip-container">
                        {canImportExcel && (
                          <Dropdown.Toggle id="dropdown-basic">
                            <div className="circle-logo-wrapper">
                              <span className="svg-icon">
                                <img src={exportExcel} alt="Excel" />
                              </span>
                            </div>
                          </Dropdown.Toggle>
                        )}
                        <div className="tooltip-text">Import/Export Excel</div>
                      </div>
                      <Dropdown.Menu>
                        <Dropdown.ItemText>Options</Dropdown.ItemText>
                        <Dropdown.Item as="button" onClick={importExcel}>
                          Import Excel
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={exportExcelData}>
                          Export Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown className="create-modal-dropdown export-dropdown">
                      <div className="tooltip-container">
                        {canImportJSON && !isSharedOrLockScenario && (
                          <Dropdown.Toggle id="dropdown-basic">
                            <div className="circle-logo-wrapper">
                              <span className="svg-icon">
                                <img src={exportJson} alt="Import/Export" />
                              </span>
                            </div>
                          </Dropdown.Toggle>
                        )}
                        <div className="tooltip-text">Import/Export JSON</div>
                      </div>
                      <Dropdown.Menu>
                        <Dropdown.ItemText>Options</Dropdown.ItemText>
                        <Dropdown.Item as="button" onClick={importJsonData}>
                          Import JSON
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => exportJsonData()}>
                          Export JSON
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {!isSharedScenario && showRequestApproval &&
                      <Button className={`btn-no-outline appoval-btn ${BaselineStatus ? 'base-line' : ''} ${!hasSharePermission ? 'request-dropdown' : ''}`}
                        disabled={!isScenarioSimulated || BaselineStatus}
                        title={!isScenarioSimulated ? 'Scenario is not simulated' : ''}
                        onClick={() => showApprovalAlert(approvalStatus)}>
                        {BaselineStatus ? 'Baseline Scenario' : getApprovalStaus(approvalStatus, approverList)}
                      </Button>
                    }
                  </>
                )}

                {!isSharedScenario && hasSharePermission && <NavDropdown
                  title="Share"
                  id="collapsible-nav-dropdown"
                  className="request-dropdown"
                  onClick={() => setShowShareModal(true)}
                >
                  <span>1</span>
                </NavDropdown>}
                <button className="close-btn" onClick={() => showUnsavedAlert()} >
                  <span className="header-close-icon"><img src={headerCloseBtn} alt="search" /></span>
                </button>
                {/* JSON input type file */}
                <input type="file" accept=".json" ref={fileInputRef} className="d-none" onChange={handleImportJSON} />
                {/* excel input type file */}
                <input type="file" accept=".xlsx" ref={excelFileInputRef} className="d-none" onChange={importExcelData}
                />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      {/* setting modal start here */}
      {scenarioType !== EV && <WorkBenchSettings
        showSettingModal={show}
        hideSettingModal={() => setShow(false)}
      ></WorkBenchSettings>}

      {/* share modal start here */}
      <ScenarioSharedList
        showModal={showShareModal}
        approvalStatus={approvalStatus}
        setShowModal={setShowShareModal}
      />
      {confirmationAlert && (
        <ConfirmationAlert
          btnClassName="blue-btn"
          cancelBtnClassName="danger-text"
          showAlert={confirmationAlert}
          showLoader={showLoading}
          title={isEVHeader ? LABELS.UNSAVED_EV_SIMULATION_CHANGES : LABELS.UNSAVED_CHANGES}
          message={isEVHeader ? MESSAGES.EV_SIMULATION_CONFIRM_MESSAGE : MESSAGES.WORKBENCH_CONFIRM_MESSAGE}
          yesBtnText={LABELS.SAVE_CHANGES}
          cancleBtnText={LABELS.DISCARD_CHANGES}
          onConfirm={handleSaveChangesClick}
          onCancel={handleWorkBenchCloseClick}
          onCross={() => setConfirmationAlert(false)}
        />
      )}

      {
        showStartApprovalAlert && (
          <ConfirmationAlert
            btnClassName="blue-btn"
            showAlert={showStartApprovalAlert}
            title={LABELS.START_APPROVAL}
            message={MESSAGES.START_APPROVAL}
            yesBtnText={LABELS.YES_START}
            cancleBtnText={LABELS.NOT_NOW}
            onConfirm={startApprovalProcess}
            onCancel={() => setShowStartApprovalAlert(false)}
            onCross={() => setShowStartApprovalAlert(false)}
          />
        )
      }

      {
        approvalModal && <RequestApproval
          approvalModal={approvalModal}
          setApprovalModal={setApprovalModal}
        />
      }
    </>
  );
}

export default HeaderWorkbench;
